import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import moment from "moment";
import { baseUrl, fetchApi } from "../../../Utiles/connections";

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
  };
};

const SeminarCard = ({ seminar, setPath }: any) => {
  const navigate = useNavigate();
  const navigateTo = (path: string) => {
    navigate(path);
    setPath(path);
  };

  const getSeminar = async (id: number) => {
    const response = await fetchApi(`Member/GetSeminar/${id}`, "GET", {});
    if (response.StatusCode === 200) {
      return response.My_Result;
    } else {
      alert("Something went wrong");
    }
  };
  return (
    <div
      className="member w-100 shadow"
      onClick={() => {
        navigateTo(`/seminars/seminar?id=${seminar.IdSeminar}`);
      }}>
      <div className="member-img">
        {/* random image */}
        <img
          src={
            baseUrl + seminar.ImageURL ||
            "https://www.w3schools.com/w3images/avatar2.png"
          }
          className="img-fluid w-100"
          style={{ aspectRatio: "8/5" }}
          alt=""
        />
      </div>
      <div className="member-info">
        <h4>{seminar.Title}</h4>
        <span className="fw-bold mb-1">
          {moment(seminar.Date).format("DD-MM-YYYY")} |{" "}
          {
            //i have the time of this format 10:00:00
            //how can i convert it to 10:00 AM or PM
            seminar.Time.split(":")[0] > 12
              ? `${seminar.Time.split(":")[0] - 12}:${
                  seminar.Time.split(":")[1]
                } PM`
              : `${seminar.Time.split(":")[0]}:${seminar.Time.split(":")[1]} AM`
          }
        </span>
        <div className="fw-bold">Price: {seminar.Price}</div>
        {/* <p className="text-center">{seminar.Description}</p> */}
        {/* <button className="navIcons" style={{ zIndex: 1 }}>
          Join Seminar
        </button> */}
      </div>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(SeminarCard);
