import React from "react";
import "./Loader.css";

function Laoder() {
  return (
    <div className="vh-100 w-100 d-flex align-items-center justify-content-center">
      <span className="loader"></span>
    </div>
  );
}

export default Laoder;
