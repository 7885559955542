import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";

const mapStateToProps = (state: any) => {
  return {
    path: state.path,
    navigation: state.navigation,
    services: state.services,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
  };
};

const Footer = ({ setPath, navigation, services }: any) => {
  const navigate = useNavigate();

  const navigateTo = (path: string) => {
    window.scrollTo(0, 0);
    sessionStorage.setItem("path", path);
    setPath(path);
    navigate(path);
  };

  return (
    <div>
      <footer id="footer">
        {/* <div className="footer-newsletter">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h4>Our Newsletter</h4>
                <p>
                  Tamen quem nulla quae legam multos aute sint culpa legam
                  noster magna
                </p>
              </div>
              <div className="col-lg-6">
                <form action="" method="post">
                  <input type="email" name="email" />
                  <input type="submit" value="Subscribe" />
                </form>
              </div>
            </div>
          </div>
        </div> */}

        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  {navigation.map((item: any, index: number) => (
                    <li onClick={() => navigateTo(item.path)} key={index}>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <button>{item.title}</button>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  {services.map((service: any, index: number) => (
                    <li key={index}>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <button onClick={() => navigateTo(service.path)}>
                        {service.name}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-contact">
                <h4>Contact Us</h4>
                <p>
                  85 Great Portland Street, <br />
                  London, W1W 7LT,
                  <br />
                  United Kingdom.
                  <br />
                  <br />
                  <strong>Phone:</strong>{" "}
                  <a href="tel:+44 7445141002">+44 7445141002</a>
                  <br />
                  <strong>Email:</strong>{" "}
                  <a href="mailto:contact@novalaw.legal">
                    contact@novalaw.legal
                  </a>
                  <br />
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      navigateTo("/terms-and-conditions");
                    }}>
                    Terms and Comditions
                  </a>
                </p>
              </div>

              <div className="col-lg-3 col-md-6 footer-info">
                <h3>
                  About{" "}
                  <span className="customFont">
                    Nova<span className="fw-bold">Law</span>
                  </span>
                </h3>
                <p>
                  At Nova<span className="fw-bold">Law</span>, we are
                  revolutionizing the legal landscape by providing a
                  comprehensive platform for both lawyers and individuals
                  passionate about law.
                </p>
                <div className="social-links mt-3">
                  <a href="#" className="twitter">
                    <i className="bx bxl-twitter"></i>
                  </a>
                  <a href="#" className="facebook">
                    <i className="bx bxl-facebook"></i>
                  </a>
                  <a href="#" className="instagram">
                    <i className="bx bxl-instagram"></i>
                  </a>
                  <a href="#" className="linkedin">
                    <i className="bx bxl-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span className="customFont">
                Nova<span className="fw-bold">Law</span>
              </span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed by <a href="https://asynk.tech">Asynk Tech</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
