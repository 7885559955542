import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import SeminarCard from "../components/SeminarCard";
import { fetchApi } from "../../../Utiles/connections";
import CardLoader from "../../Shared/CardLoader.tsx/CardLoader";

const mapStateToProps = (state: any) => {
  return {
    path: state.path,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: any) => dispatch({ type: "SET_PATH", path }),
  };
};

function Seminars({ setPath, path }: { setPath: any; path: any }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [seminars, setSeminars] = useState<any>([]);
  const [offset, setOffset] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    getSeminars();
  }, []);

  const getSeminars = async () => {
    setLoading(true);
    const response = await fetchApi(
      `Member/GetSeminars?Offset=${offset}`,
      "GET",
      {}
    );

    if (response.StatusCode === 200) {
      if (response.My_Result.length < 20) {
        setHasMore(false);
        setLoading(false);
        setSeminars([...seminars, ...response.My_Result]);
      }
      setSeminars(
        seminars.length > 0
          ? [...seminars, ...response.My_Result]
          : response.My_Result
      );
    } else {
      alert("Something went wrong");
    }
    setLoading(false);
  };

  return (
    <>
      <main id="main">
        <section
          className="team pb-0"
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="500">
          <div className="container">
            <div className="row">
              {seminars.map((seminar: any, index: number) => (
                <div
                  className="col-lg-4 col-12 d-flex align-items-stretch"
                  key={index}>
                  <SeminarCard seminar={seminar} />
                </div>
              ))}
              {loading &&
                Array.from({ length: 6 }).map((_: any, index: number) => {
                  return (
                    <div
                      className="col-lg-4 col-md-6 mb-3 d-flex align-items-stretch"
                      key={index}>
                      <CardLoader />
                    </div>
                  );
                })}

              {hasMore && !loading && (
                <div className="d-flex justify-content-center">
                  <button
                    className="navIcons w-100"
                    onClick={() => {
                      setOffset(offset + 20);
                    }}>
                    Load More
                  </button>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Seminars);
