import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../Utiles/connections";
import moment from "moment";
import { fetchApi } from "../../../Utiles/connections";
import { ApiRes } from "../../../Utiles/types";

const SeminarDetails = () => {
  const [seminar, setSeminar] = useState({
    Title: "",
    Date: new Date(),
    Time: "",
    Description: "",
    ImageURL: "",
    SeminarStatus: true,
    Price: 0,
  });
  const [loading, setLoading] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const [IdSeminar, setIdSeminar] = useState<string>(params.get("id") || "");

  useEffect(() => {
    getSeminar();
  }, [IdSeminar]);

  const getSeminar = async () => {
    setLoading(true);
    const response: ApiRes = await fetchApi(
      `Member/GetSeminar?IdSeminar=${IdSeminar}`,
      "GET",
      {}
    );
    if (response.StatusCode === 200) {
      setSeminar(response.My_Result);
    } else {
      alert("Something went wrong");
    }
    setLoading(false);
  };

  return (
    <section id="blog" className="blog">
      <div className="container" data-aos="fade-up">
        {!loading && seminar ? (
          <div className="row">
            <div className="col-lg-4">
              <div className=" bg-white shadow ">
                <img
                  src={
                    seminar.ImageURL
                      ? `${baseUrl}${seminar.ImageURL}`
                      : "/assets/img/blog/blog-1.jpg"
                  }
                  alt=""
                  className="img-fluid"
                />
                <div
                  className="p-3 text-center d-flex flex-column"
                  style={{ gap: "10px" }}>
                  <h4>{seminar.Title}</h4>
                  {/* <a href={`mailto:${seminar.Email}`}>
                {seminar.Email}
              </a> */}
                  {/* <a href={`tel:${seminar.Phone}`}>
                {seminar.Phone}
              </a> */}
                  {/* <a
                href={`${baseUrl}${seminar.CV}`}
                target="_blank">
                Lawyer CV
              </a> */}
                  <p>{seminar.Price}</p>

                  <p>
                    {moment(seminar.Date).format("DD/MM/YYYY")} |
                    {parseInt(seminar.Time.split(":")[0]) > 12
                      ? `${parseInt(seminar.Time.split(":")[0]) - 12}:${
                          seminar.Time.split(":")[1]
                        } PM`
                      : `${seminar.Time.split(":")[0]}:${
                          seminar.Time.split(":")[1]
                        } AM`}
                  </p>

                  {/* <div className="d-flex align-items-center justify-content-center">
                    {seminar.SocialMedia?.map((media: any, index: number) => (
                      <a
                        key={index}
                        className="mx-1 navIcons rounded-5 d-flex align-items-center justify-content-center"
                        style={{ height: "2.5rem", width: "2.5rem" }}
                        href={media.Link}
                        target="_blank">
                        <i className={`bi bi-${media.Icon}`}></i>
                      </a>
                    ))}
                  </div> */}
                  <button className="navIcons w-100">Join Seminar</button>
                </div>
              </div>
            </div>
            <div className="col-lg-8 entries">
              <article className="entry entry-single">
                <div className="entry-content">
                  <p>{seminar.Description}</p>
                </div>
              </article>
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "75vh" }}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default SeminarDetails;
