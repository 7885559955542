import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
  return {
    path: state.path,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
  };
};

const BreadCrumb = ({ path, setPath }: any) => {
  const navigate = useNavigate();
  console.log(path);
  const [splitPath, setSplitPath] = React.useState<string[]>(path.split("/"));

  const naviagateTo = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
    sessionStorage.setItem("path", path);
    setPath(path);
  };

  React.useEffect(() => {
    console.log(path);
    setSplitPath(path.split("/"));
  }, [path]);

  return (
    <section className="breadcrumbs">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h2 style={{ textTransform: "capitalize" }}>
            {splitPath[splitPath.length - 1].split("-").join(" ").split("?")[0]}
          </h2>
          <ol>
            <li>
              <button onClick={() => naviagateTo("/")}>Home</button>
            </li>
            {splitPath.map((item: any, index: any) => {
              return (
                index !== 0 && (
                  <li style={{ textTransform: "capitalize" }} key={index}>
                    {item.split("-").join(" ").split("?")[0]}
                  </li>
                )
              );
            })}
          </ol>
        </div>
      </div>
    </section>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumb);
