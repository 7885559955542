const initialState = {
  navigation: [
    {
      id: 0,
      name: "Home",
      path: "/",
      icon: "bi bi-house",
    },
    {
      id: 1,
      name: "All Courses",
      path: "/members/courses",
      icon: "bi bi-mortarboard",
    },
    {
      id: 2,
      name: "My Courses",
      path: "/members/my-courses",
      icon: "bi bi-book",
    },
  ],
  path: sessionStorage.getItem("memberPath") || "/members",
  open: false,
  profileOpen: false,
  token: localStorage.getItem("ltoken") || localStorage.getItem("token") || null,
};

const rootReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_PATH":
      return { ...state, path: action.payload };
    case "SET_OPEN":
      return { ...state, open: action.payload };
    case "SET_PROFILE_OPEN":
      return { ...state, profileOpen: action.payload };
    default:
      return state;
  }
};
export default rootReducer;
