import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { ApiRes } from "../../../../Utiles/types";
import { baseUrl, fetchApi } from "../../../../Utiles/connections";
import CardLoader from "../../../Shared/CardLoader.tsx/CardLoader";

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
  };
};

const Portfolio = ({ setPath }: any) => {
  const navigate = useNavigate();
  const navigateTo = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
    sessionStorage.setItem("path", path);
    setPath(path);
  };

  const [contracts, setContracts] = useState<any>([]);
  const [Loading, setLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const GetContracts = async () => {
    setLoading(true);
    const responce: ApiRes = await fetchApi("Member/GetContracts", "Get", {});
    if (responce.StatusCode === 200) {
      setContracts(responce.My_Result);
    } else {
      alert("Something went wrong, please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    GetContracts();
  }, []);

  return (
    <>
      <main id="main">
        <section className="portfolio">
          <div className="container">
            {/* <div className="row">
              <div className="col-lg-12">
                <ul id="portfolio-flters">
                  <li data-filter="*" className="filter-active">
                    All
                  </li>
                  <li data-filter=".filter-app">App</li>
                  <li data-filter=".filter-card">Card</li>
                  <li data-filter=".filter-web">Web</li>
                </ul>
              </div>
            </div> */}

            <div
              className="row portfolio-container"
              data-aos="fade-up"
              data-aos-easing="ease-in-out"
              data-aos-duration="500">
              {contracts.map((contract: any, index: number) => (
                <div
                  className="col-lg-4 col-md-6 portfolio-wrap filter-app h-100"
                  key={index}
                  onClick={() =>
                    navigateTo(
                      `/contracts/contract-details?id=${contract.IdContract}`
                    )
                  }>
                  <div className="portfolio-item">
                    <img
                      src={`${baseUrl}${contract.ImageURL}`}
                      className="img-fluid w-100"
                      alt=""
                      style={{ aspectRatio: "1/1" }}
                    />
                    <div className="portfolio-info text-capitalize">
                      <h3>{contract.Title}</h3>
                      <h3>{contract.Price}</h3>
                      <div>
                        <a
                          onClick={() =>
                            navigateTo(
                              `/contracts/contract-details?id=${contract.IdContract}`
                            )
                          }
                          title="Portfolio Details">
                          <i className="bx bx-link"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {Loading &&
                Array.from({ length: 6 }).map((_: any, index: number) => {
                  return (
                    <div
                      className="col-lg-4 col-md-6 mb-3 d-flex align-items-stretch"
                      key={index}>
                      <CardLoader />
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default connect(null, mapDispatchToProps)(Portfolio);
