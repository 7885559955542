import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Navigation from "./Main/navigation/Navigation";
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "../reducers/rootReducer";
import memberReducer from "../reducers/memberReducer";
import adminReducer from "../reducers/adminReducer";
import Error from "./Shared/Error";
import lawyerReducer from "../reducers/lawyerReducer";
import Loader from "./Shared/Loader/Loader";

const store = createStore(rootReducer);
const memberStore = createStore(memberReducer);
const adminStore = createStore(adminReducer);
const lawyerStore = createStore(lawyerReducer);

export default function Modules() {
  const MembersNavigation = React.lazy(() => import("./Members/navigation/Navigation"));
  const AdminNavigation = React.lazy(() => import("./Admin/navigation/Navigation"));
  const LawyerNavigation = React.lazy(() => import("./Lawyers/navigation/Navigation"));
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="/members/*"
            element={
              <Provider store={memberStore}>
                <MembersNavigation />
              </Provider>
            }
          />
          <Route
            path="/Lawyer/*"
            element={
              <Provider store={lawyerStore}>
                <LawyerNavigation />
              </Provider>
            }
          />
          <Route
            path="/admin/*"
            element={
              <Provider store={adminStore}>
                <AdminNavigation />
              </Provider>
            }
          />

          <Route
            path="/*"
            element={
              <Provider store={store}>
                <Navigation />
              </Provider>
            }
          />
          <Route path="*" element={<Error />} />
        </Routes>
      </Suspense>
    </>
  );
}
