const PROD_FETCH_URL = "https://novalaw.asynktech.com/";
export const baseUrl = "https://novalaw.asynktech.com";
// export const USER_NAME_SOCKET_URL = `${PROD_FETCH_URL}Sockets/UserNames`;
// export const NOTIFICATIONS_SOCKET_URL = `${PROD_FETCH_URL}Sockets/Users`;
// export const POSTS_READ_SOCKET_URL = `${PROD_FETCH_URL}Sockets/Posts`;
// export const ADMIN_SOCKET_URL = `${PROD_FETCH_URL}Sockets/Admins`;

export const getLocalData = async (key: string) => {
  try {
    const value = await localStorage.getItem(key);
    if (value !== null) {
      return value;
    }
  } catch (e) {
    console.warn(e);
  }
};

export const fetchApi = async (
  path: string,
  method: string,
  body: any,
  formData?: boolean
) => {
  let authorization;
  if (
    path.split("/")[0] === "admin" ||
    path.split("/")[0] === "Admin" ||
    path.split("/")[0] === "ADMIN"
  ) {
    authorization = await getLocalData("atoken");
  } else {
    authorization = await getLocalData("token");
    if (!authorization) {
      authorization = await getLocalData("temptoken");
    }
  }
  //   const xAccessToken = await getLocalData("token");

  const headers: Record<string, string> = {};

  if (!formData) {
    headers["Content-Type"] = "application/json";
  }

  if (authorization) headers.Authorization = authorization;
  const options: { method: string; headers: any; body?: string } = {
    method: method,
    headers: headers,
  };

  if (method === "POST" || method === "PUT" || method === "DELETE") {
    console.log("body", body);
    options.body = formData ? body : JSON.stringify(body);
  }

  // console.warn(`${PROD_FETCH_URL}${path}`, options);

  try {
    console.log("path", path);

    const response = await fetch(`${PROD_FETCH_URL}${path}`, options);

    const json = await response.json();
    // console.warn(json);
    if (json.StatusCode === 403) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }
    if (json.StatusCode === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }
    console.log("json", json);
    return json;
  } catch (e) {
    // console.warn(e);
    if (e instanceof Error) {
      throw e; // or throw new Error(e.message);
    } else {
      throw new Error(String(e));
    }
  }
};
