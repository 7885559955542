import { get } from "http";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { ApiRes } from "../../../Utiles/types";
import { fetchApi } from "../../../Utiles/connections";

const mapStateToProps = (state: any) => {
  return {
    path: state.path,
    navigation: state.navigation,
    token: state.token,
    ltoken: state.ltoken,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
  };
};

const NavBar = ({ path, setPath, navigation, token, ltoken }: any) => {
  const navigate = useNavigate();

  const [mobileToggle, setMobileToggle] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let verticalScrollPosition = window.scrollY;
      if (verticalScrollPosition > 100) {
        document.getElementById("header")?.classList.add("header-scrolled");
        document.getElementById("back-to-top")?.classList.add("active");
      } else {
        document.getElementById("header")?.classList.remove("header-scrolled");
        document.getElementById("back-to-top")?.classList.remove("active");
      }
    });
    return () => {
      window.removeEventListener("scroll", function () {
        let verticalScrollPosition = window.scrollY;
        if (verticalScrollPosition > 100) {
          document.getElementById("header")?.classList.add("header-scrolled");
          document.getElementById("back-to-top")?.classList.add("active");
        } else {
          document.getElementById("header")?.classList.remove("header-scrolled");
          document.getElementById("back-to-top")?.classList.remove("active");
        }
      });
    };
  }, []);

  const CheckIfLoggedInElseMakeTempUser = async () => {
    if (token || ltoken) return;
    const responce: ApiRes = await fetchApi("Auth/TemporaryUser", "Post", {});
    if (responce.StatusCode === 200) {
      localStorage.setItem("temptoken", responce.My_Result.JWT_Token);
    } else {
      console.log("error", responce);
    }
  };

  useEffect(() => {
    CheckIfLoggedInElseMakeTempUser();
  }, []);

  const navigateTo = (p: string) => {
    window.scrollTo(0, 0);
    setMobileToggle(false);
    sessionStorage.setItem("path", p);
    setPath(p);
    navigate(p);
    console.log("path", p);
  };

  const logout = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to logout?")) {
      localStorage.removeItem("token");
      localStorage.removeItem("ltoken");
      window.location.reload();
    }
  };

  return (
    <>
      <header id="header" className={`fixed-top d-flex align-items-center ${path === "/" && "header-transparent"}`}>
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <div className="logo">
            <h1 className="text-light">
              <a href="">
                <img src="/assets/images/nova law white logo.png" className="me-2" />
                <span style={{ fontFamily: "Times New Roman" }}>{/* Nova<strong>Law</strong> */}</span>
              </a>
            </h1>
            {/* <a href="index.html"><img src="assets/img/logo.png" alt="" className="img-fluid"></a> */}
          </div>

          <nav id="navbar" className={`navbar ${mobileToggle && "navbar-mobile"}`}>
            <ul>
              {navigation.map((item: any) => {
                return (
                  <li key={item.id}>
                    <button className={`${path === item.path && "active"}`} onClick={() => navigateTo(item.path)}>
                      {item.title}
                    </button>
                  </li>
                );
              })}
              {token ? (
                <>
                  <li>
                    <button className={``} onClick={() => navigateTo(`${ltoken ? "/lawyer/profile" : "/profile"}`)}>
                      Profile
                    </button>
                  </li>
                  <li>
                    <button className={`member-btn`} onClick={logout}>
                      Logout
                    </button>
                  </li>
                </>
              ) : (
                <li>
                  <button className={`member-btn`} onClick={() => navigateTo("/login")}>
                    Login
                  </button>
                </li>
              )}
            </ul>
            <i className={`bi bi-${mobileToggle ? "x" : "list"} mobile-nav-toggle`} onClick={() => setMobileToggle(!mobileToggle)}></i>
          </nav>
        </div>
      </header>
      <a href="#" id="back-to-top" className="back-to-top d-flex align-items-center justify-content-center">
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
