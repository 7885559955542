interface initialStateType {
  navigation: Array<object>;
  path: string;
  open: boolean;
  admin: {
    Email: string;
    IdAdmin: number;
  };
  token: string | null;
}

const initialState: initialStateType = {
  navigation: [
    {
      id: 0,
      name: "Home",
      path: "/",
      icon: "bi bi-house",
    },
    {
      id: 1,
      name: "Dashboard",
      path: "/admin/dashboard",
      icon: "bi bi-bar-chart-line-fill",
    },
    {
      id: 2,
      name: "Courses",
      path: "/admin/courses",
      icon: "bi bi-book",
    },
    {
      id: 3,
      name: "Members",
      path: "/admin/members",
      icon: "bi bi-people",
    },
    {
      id: 5,
      name: "Lawyers",
      path: "/admin/lawyers",
      icon: "bi bi-person",
    },
    {
      id: 6,
      name: "Contracts",
      path: "/admin/contracts",
      icon: "bi bi-chat-left-text",
    },
    {
      id: 8,
      name: "Seminars",
      path: "/admin/seminars",
      icon: " bi bi-calendar-week",
    },
    {
      id: 7,
      name: "Promo Codes",
      path: "/admin/promo-codes",
      icon: "bi bi-gift",
    },

    {
      id: 4,
      name: "Settings",
      path: "/admin/settings",
      icon: "bi bi-gear",
    },
  ],
  path: localStorage.getItem("adminPath") || "/admin",
  open: false,
  admin: {
    Email: "",
    IdAdmin: 0,
  },
  token: localStorage.getItem("atoken") || null,
};

const rootReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_PATH":
      return { ...state, path: action.payload };
    case "SET_OPEN":
      return { ...state, open: action.payload };
    case "SET_ADMIN":
      return { ...state, Admin: action.payload };
    case "SET_TOKEN":
      return { ...state, token: action.payload };

    default:
      return state;
  }
};
export default rootReducer;
