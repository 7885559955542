import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { servicesColor } from "../../../../Utiles/constants";

const mapStateToProps = (state: any) => {
  return {
    services: state.services,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
  };
};

const Home = ({ services, setPath }: any) => {
  const navigate = useNavigate();

  const NavigateTo = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
    sessionStorage.setItem("path", path);
    setPath(path);
  };

  return (
    <div>
      <section
        id="hero"
        className="d-flex justify-cntent-center align-items-center">
        <div
          id="heroCarousel"
          className="container carousel carousel-fade"
          data-bs-ride="carousel"
          data-bs-interval="5000">
          <div className="carousel-item active">
            <div className="carousel-container">
              <h2
                className="animate__animated animate__fadeInDown"
                style={{ fontFamily: "Bell MT", fontWeight: 200 }}>
                Welcome to{" "}
                <span>
                  Nova<span className="fw-bold">Law</span>
                </span>
              </h2>
              <p className="animate__animated animate__fadeInUp">
                Dive into a wealth of information, stay updated on legal trends,
                and empower yourself with the knowledge to navigate the
                intricacies of the law. Welcome to a platform where your legal
                journey begins and where understanding the law becomes
                accessible to all.
              </p>
              {/* <a className="btn-get-started animate__animated animate__fadeInUp">
                Read More
              </a> */}
            </div>
          </div>

          {/* <div className="carousel-item">
            <div className="carousel-container">
              <h2 className="animate__animated animate__fadeInDown">
                Lorem Ipsum Dolor
              </h2>
              <p className="animate__animated animate__fadeInUp">
                Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et
                est quaerat sequi nihil ut aliquam. Occaecati alias dolorem
                mollitia ut. Similique ea voluptatem. Esse doloremque accusamus
                repellendus deleniti vel. Minus et tempore modi architecto.
              </p>
              <a
                href=""
                className="btn-get-started animate__animated animate__fadeInUp">
                Read More
              </a>
            </div>
          </div>

          <div className="carousel-item">
            <div className="carousel-container">
              <h2 className="animate__animated animate__fadeInDown">
                Sequi ea ut et est quaerat
              </h2>
              <p className="animate__animated animate__fadeInUp">
                Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et
                est quaerat sequi nihil ut aliquam. Occaecati alias dolorem
                mollitia ut. Similique ea voluptatem. Esse doloremque accusamus
                repellendus deleniti vel. Minus et tempore modi architecto.
              </p>
              <a
                href=""
                className="btn-get-started animate__animated animate__fadeInUp">
                Read More
              </a>
            </div>
          </div>

          <a
            className="carousel-control-prev"
            href="#heroCarousel"
            role="button"
            data-bs-slide="prev">
            <span
              className="carousel-control-prev-icon bx bx-chevron-left"
              aria-hidden="true"></span>
          </a>

          <a
            className="carousel-control-next"
            href="#heroCarousel"
            role="button"
            data-bs-slide="next">
            <span
              className="carousel-control-next-icon bx bx-chevron-right"
              aria-hidden="true"></span>
          </a> */}
        </div>
      </section>

      <main id="main">
        <section className="services">
          <div className="container">
            <div className="row">
              {services.map((service: any, index: number) => (
                <div
                  key={index}
                  className="col-md-6 col-lg-3 d-flex align-items-stretch"
                  data-aos="fade-up"
                  onClick={() => {
                    NavigateTo(service.path);
                    setPath(service.path);
                  }}>
                  <div
                    className={`icon-box w-100 icon-box-${
                      servicesColor[index % 4]
                    }`}>
                    <div className="icon">
                      <i className={service.icon}></i>
                    </div>
                    <h4 className="title">
                      <a href="">{service.name}</a>
                    </h4>
                    {/* <p className="description">{service.desc}</p> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section
          className="why-us section-bg"
          data-aos="fade-up"
          date-aos-delay="200">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 video-box">
                <div className="h-100 w-100" style={{ position: "relative" }}>
                  <iframe
                    src="https://player.vimeo.com/video/981304157?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    title="New NOVA entrance"></iframe>
                </div>
                {/* <script src="https://player.vimeo.com/api/player.js"></script> */}
              </div>

              <div className="col-lg-6 d-flex flex-column justify-content-center p-5">
                <div className="icon-box">
                  <div className="icon">
                    {/* <i className="bx bx-fingerprint"></i> */}
                    <img
                      src="/assets/images/unnamed (1).png"
                      alt=""
                      className="rounded "
                      height="70"
                      width="70"
                    />
                  </div>
                  <h4 className="title">
                    <a href="">Legal Assistance Services:</a>
                  </h4>
                  <p className="description">
                    Connect with a tailored lawyer and access pre-prepared legal
                    documents for quick use. Streamline your legal processes and
                    save time with our platform.
                  </p>
                </div>

                <div className="icon-box">
                  <div className="icon">
                    {/* <i className="bx bx-gift"></i> */}
                    <img
                      src="/assets/images/unnamed.png"
                      alt=""
                      className="rounded"
                      height="70"
                      width="70"
                    />
                  </div>
                  <h4 className="title">
                    <a href="">Educational Resources:</a>
                  </h4>
                  <p className="description">
                    Explore recorded training sessions for valuable insights and
                    join monthly legal forums to stay informed about the latest
                    developments, engaging with legal experts.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features">
          <div className="container">
            <div className="section-title">
              <h2>Features</h2>
              <p>
                Our legal platform prioritizes your efficiency and convenience,
                with each feature crafted to enhance and simplify your
                experience. We go beyond addressing immediate legal needs,
                providing ongoing support and empowerment.
              </p>
            </div>

            <div className="row" data-aos="fade-up">
              <div className="col-md-5">
                <img src="assets/images/1.png" className="img-fluid" alt="" />
              </div>
              <div className="col-md-7 pt-4">
                <h3>
                  This ensures that you possess the necessary tools for informed
                  decision-making throughout your entire legal journey.
                </h3>
                <p className="fst-italic mt-2">
                  We understand the significance of efficiency and convenience
                  in navigating legal processes, and our platform reflects this
                  commitment
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check"></i> Ease of Access
                  </li>
                  <li>
                    <i className="bi bi-check"></i> streamlined Processes
                  </li>
                </ul>
              </div>
            </div>

            <div className="row" data-aos="fade-up">
              <div className="col-md-5 order-1 order-md-2">
                <img src="assets/images/2.png" className="img-fluid" alt="" />
              </div>
              <div className="col-md-7 pt-5 order-2 order-md-1">
                <h3>
                  Effortless Legal Solutions: Streamlining Contracts and Expert
                  Consultations
                </h3>
                <p className="fst-italic mt-2">
                  From streamlining the creation of legally sound contracts
                  through our intuitive contract generation tool to facilitating
                  personalized consultations with our seasoned lawyers, we
                  prioritize simplicity and effectiveness.
                </p>
                <p></p>
              </div>
            </div>

            <div className="row" data-aos="fade-up">
              <div className="col-md-5">
                <img src="assets/images/3.png" className="img-fluid" alt="" />
              </div>
              <div className="col-md-7 pt-5">
                <h3>
                  Empowering Your Legal Journey: Ongoing Education and
                  Insightful Seminars
                </h3>
                <p></p>
                <ul>
                  <li>
                    <i className="bi bi-check"></i> Ongoing Education: We
                    provide legal training sessions and monthly seminars.
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Comprehensive Approach: Our
                    services go beyond addressing immediate legal needs.
                  </li>
                  <li>
                    <i className="bi bi-check"></i> Empowerment: Stay informed
                    and prepared in the constantly changing legal landscape.
                  </li>
                </ul>
              </div>
            </div>

            <div className="row d-flex align-items-center" data-aos="fade-up">
              <div className="col-md-5 order-1 order-md-2 text-center">
                <h3 className="my-3">Join Our Newsletter</h3>
                <p className="fst-italic">
                  Join our newsletter to receive the latest news and updates
                  from our team.
                </p>
                <div className="d-flex">
                  <input type="text" className="form-control me-2 rounded-5" />
                  <button className="member">Subscribe</button>
                </div>
              </div>
              <div
                className="col-md-7 pt-5 order-2 order-md-1"
                style={{ paddingRight: "7rem" }}>
                <h3>Our platform is more than just a service</h3>
                <p className="fst-italic">
                  It's a reliable companion on your legal journey, providing the
                  tools and expertise you need for a confident and informed
                  decision-making process.
                </p>
                {/* <p>
                  Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                  aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore eu fugiat nulla pariatur. Excepteur sint
                  occaecat cupidatat non proident, sunt in culpa qui officia
                  deserunt mollit anim id est laborum
                </p> */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
