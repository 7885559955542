import React, { useEffect, useRef, useState } from "react";
import { fetchApi } from "../../../Utiles/connections";
import { ApiRes } from "../../../Utiles/types";
import Loader2 from "../../Shared/Loader2/Loader2";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setUser: (user: any) => dispatch({ type: "SET_USER", payload: user }),
  };
};

function Profile({ user }: any) {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [Phone, setPhone] = React.useState<string>("");
  const [FullName, setFullName] = React.useState<string>("");
  const [ProfilePicture, setProfilePicture] = React.useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [image, setImage] = useState<any>(null);
  const id = useRef<any>(null);

  const [CurrentPassword, setCurrentPassword] = React.useState<string>("");
  const [NewPassword, setNewPassword] = React.useState<string>("");
  const [ConfirmPassword, setConfirmPassword] = React.useState<string>("");

  useEffect(() => {}, []);

  const _ChangeProfile = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    const response: ApiRes = await fetchApi(
      "Member/UpdateMemberProfile",
      "PUT",
      {
        FullName,
        Phone,
        ProfilePicture,
      }
    );
    if (response.StatusCode === 200) {
      alert("Profile Updated Successfully");
    } else {
      alert("Something went wrong, please try again.");
    }
    setLoading(false);
  };

  const _ChangePassword = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    if (NewPassword !== ConfirmPassword) {
      alert("Password does not match");
      return;
    }
    const response: ApiRes = await fetchApi(
      "AUTH/ResetMemberPassword",
      "POST",
      {
        CurrentPassword,
        NewPassword,
      }
    );
    if (response.StatusCode === 200) {
    } else {
      alert("Something went wrong, please try again.");
    }
    setLoading(false);
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const UpdateImage = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("IMAGE", image);
    formData.append("IdMember", id.current);
    const response: ApiRes = await fetchApi(
      "Member/UpdateMemberProfilePicture",
      "POST",
      {
        formData,
      },
      true
    );
    if (response.StatusCode === 200) {
      alert("Profile Picture Updated Successfully");
      setProfilePicture(URL.createObjectURL(image));
      setImage(null);
    } else {
      alert("Something went wrong, please try again.");
    }
    setLoading(false);
  };

  const getUserProfile = async () => {
    setLoading(true);
    const response: ApiRes = await fetchApi(
      "Member/GetMemberProfile",
      "GET",
      {}
    );
    if (response.StatusCode === 200) {
      setFullName(response.My_Result.FullName);
      setPhone(response.My_Result.Phone);
      setProfilePicture(response.My_Result.ProfilePicture);
      id.current = response.My_Result.Id;
    } else {
      alert("Something went wrong, please try again.");
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <div>
      <div className="container p-2">
        <section className="section-title">
          <h2 className="">Profile</h2>
        </section>
        {loading && <Loader2 />}
        <div className="row">
          <div className="col-lg-6 col-12 px-2">
            <div className="bg-white rounded-4 p-3 shadow">
              <div className="d-flex justify-content-center align-items-center flex-column mb-3">
                <img
                  src={
                    image
                      ? URL.createObjectURL(image)
                      : ProfilePicture
                      ? ProfilePicture
                      : "/assets/images/placeholder.png"
                  }
                  alt="profile"
                  className="rounded-circle"
                  style={{
                    aspectRatio: "1/1",
                    maxHeight: "15rem",
                    opacity: image || ProfilePicture ? 1 : 0.5,
                  }}
                />
                <input
                  id="pp"
                  type="file"
                  className="d-none"
                  onChange={handleImageChange}
                  accept=".png,.jpg,.jpeg"
                />
                <label
                  className="navIcons w-75 text-center mt-2 cursor-pointer"
                  htmlFor="pp">
                  Change Image
                </label>
                {image && (
                  <button
                    className="navIcons w-75 text-center mt-2"
                    onClick={UpdateImage}>
                    Save Image
                  </button>
                )}
              </div>

              <form
                className="d-flex flex-column pt-3 border-top"
                onSubmit={_ChangeProfile}>
                <label className="">Full Name</label>
                <input
                  type="text"
                  placeholder="Full Name"
                  className="form-control my-2"
                  value={FullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                <label className="">Phone</label>
                <input
                  type="text"
                  placeholder="Phone"
                  className="form-control my-2"
                  value={Phone}
                  onChange={(e) => setPhone(e.target.value)}
                />

                <button className="navIcons w-100 my-2">Save</button>
              </form>
            </div>
          </div>
          <div className="col-lg-6 col-12 px-2">
            <div className="bg-white rounded-4 p-3 shadow mt-2">
              <h5>Change Password</h5>
              <form
                className="d-flex flex-column mt-2"
                onSubmit={_ChangePassword}>
                <label className="">Current Password</label>
                <div className="position-relative d-flex align-items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Full Name"
                    className="form-control my-2 pe-4"
                    value={CurrentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                  <i
                    className={`position-absolute bi bi-eye${
                      !showPassword ? "-slash" : ""
                    }`}
                    style={{ right: "0.6rem" }}
                    onClick={() => setShowPassword(!showPassword)}></i>
                </div>
                <label className="">New Password</label>
                <div className="position-relative d-flex align-items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Phone"
                    className="form-control my-2 pe-4"
                    value={NewPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <i
                    className={`position-absolute bi bi-eye${
                      !showPassword ? "-slash" : ""
                    }`}
                    style={{ right: "0.6rem" }}
                    onClick={() => setShowPassword(!showPassword)}></i>
                </div>

                <label className="">Confirm Password</label>
                <div className="position-relative d-flex align-items-center">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Phone"
                    className="form-control my-2 pe-4"
                    value={ConfirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <i
                    className={`position-absolute bi bi-eye${
                      !showPassword ? "-slash" : ""
                    }`}
                    style={{ right: "0.6rem" }}
                    onClick={() => setShowPassword(!showPassword)}></i>
                </div>
                <button className="navIcons w-100 mt-2">Change Password</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
