import React from "react";

export default function Contact() {
  return (
    <div>
      {" "}
      <main id="main">
        <section
          className="contact"
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="500">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="info-box">
                      <i className="bx bx-map"></i>
                      <h3>Our Address</h3>
                      <p>
                        85 Great Portland Street, <br />
                        London, W1W 7LT,
                        <br />
                        United Kingdom.
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box">
                      <i className="bx bx-envelope"></i>
                      <h3>Email Us</h3>
                      <p>contact@novalaw.legal</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="info-box">
                      <i className="bx bx-phone-call"></i>
                      <h3>Call Us</h3>
                      <p>+447 445 141 002</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <form className="php-email-form">
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows={5}
                      placeholder="Message"
                      required></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section className="map mt-2">
          <div className="container-fluid p-0">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d998.1248419945563!2d-0.1425811151499242!3d51.51846310462806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761ad5966688f5%3A0x7f597ce753ed6230!2s85%20Great%20Portland%20St%2C%20London%20W1W%207LT%2C%20UK!5e0!3m2!1sen!2slb!4v1710532725504!5m2!1sen!2slb"
              frameBorder={0}
              style={{ border: 0 }}
              allowFullScreen={true}></iframe>
          </div>
        </section>
      </main>
    </div>
  );
}
