import React from "react";
import { Routes, Route } from "react-router-dom";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import Portfolio from "../pages/Portfolio/Portfolio";
import PortfolioDetails from "../pages/PortfolioDetails/PortfolioDetails";
import Services from "../pages/Services/Services";
import Contact from "../pages/Contact/Contact";
import Blog from "../pages/Blog/Blog";
import SinglBlog from "../pages/SingleBlog/SinglBlog";
import Team from "../pages/Team/Team";
import SignUp from "../pages/SignUp";
import Login from "../pages/Login";
import { connect } from "react-redux";
import BreadCrumb from "../components/BreadCrumb";
import Seminars from "../pages/Seminars";
import Profile from "../pages/Profile";
import Verification from "../pages/Verification";
import ResetPassword from "../pages/ResetPassword";
import ForgotPassword from "../pages/ForgotPassword";
import ProtectedRoute from "../../Shared/ProtectedRoute/ProtectedRoute";
import SeminarDetails from "../pages/SeminarDetails";
import TermsAndConditions from "../pages/TermsAndConditions";

const mapStateToProps = (state: any) => {
  return {
    path: state.path,
    open: state.open,
    user: state.user,
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
    setOpen: (open: boolean) => dispatch({ type: "SET_OPEN", payload: open }),
  };
};

const Navigation = ({ path, setPath, open, setOpen, user, token }: any) => {
  React.useEffect(() => {
    window.addEventListener("popstate", (event) => {
      setPath(window.location.pathname);
    });
    return () => {
      window.removeEventListener("popstate", (event) => {
        setPath(window.location.pathname);
      });
    };
  }, []);

  React.useEffect(() => {
    setPath(window.location.pathname);
    localStorage.setItem("path", window.location.pathname);
  }, []);

  return (
    <>
      {path !== "/login" &&
      path !== "/signup" &&
      path !== "/verification" &&
      path !== "/reset-password" &&
      path !== "/forgot-password" ? (
        <NavBar />
      ) : null}

      <div
        className={` ${open && "active"} ${
          (path === "/login" ||
            path === "/signup" ||
            path === "/verification" ||
            path === "/reset-password" ||
            path === "/forgot-password") &&
          "background "
        }`}>
        {path !== "/" &&
        path !== "/login" &&
        path !== "/signup" &&
        path !== "/verification" &&
        path !== "/reset-password" &&
        path !== "/forgot-password" ? (
          <BreadCrumb />
        ) : null}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contracts" element={<Portfolio />} />
          <Route
            path="/contracts/contract-details"
            element={<PortfolioDetails />}
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute route="/" token={token}>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blogs/blog" element={<SinglBlog />} />
          <Route path="/find-a-lawyer" element={<Team />} />
          <Route path="/find-a-lawyer/lawyer" element={<SinglBlog />} />
          <Route path="/seminars" element={<Seminars />}></Route>
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}></Route>
          <Route path="/seminars/seminar" element={<SeminarDetails />} />

          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route
            path="/reset-password"
            element={
              <ProtectedRoute route="/error" token={user}>
                <ResetPassword />
              </ProtectedRoute>
            }
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/verification"
            element={
              <ProtectedRoute route="/error" token={user}>
                <Verification />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
      {path !== "/login" &&
      path !== "/signup" &&
      path !== "/verification" &&
      path !== "/reset-password" &&
      path !== "/forgot-password" ? (
        <Footer />
      ) : null}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
