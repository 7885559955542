import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
  return {
    modal: state.modal,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setModal: (modal: boolean) =>
      dispatch({ type: "SET_MODAL", payload: modal }),
  };
};

const LawyerModal = ({ modal, setModal }: any) => {
  const CheckCard = () => {
    alert("Your Card is not Valid");
    setModal(false);
  };
  useEffect(() => {
    console.log(modal);
  }, [modal]);
  return (
    <>
      <div
        className={`backdrop ${modal && "active"}`}
        onClick={() => setModal(false)}></div>
      <div className={`nova-law-modal ${modal && "active"} p-3`}>
        <div className="form-group mt-3">
          <input
            type="text"
            className="form-control"
            name="subject"
            id="subject"
            placeholder="Name"
            required
          />
        </div>
        <div className="form-group mt-3">
          <input
            type="text"
            className="form-control"
            name="subject"
            id="subject"
            placeholder="Card Number"
            required
          />
        </div>
        <div className="form-group mt-3">
          <input
            type="text"
            className="form-control"
            name="subject"
            id="subject"
            placeholder="CVV / CVC"
            required
          />
        </div>
        <div className="form-group mt-3">
          <input
            type="date"
            className="form-control"
            name="subject"
            id="subject"
            placeholder="Expiry Date"
            required
          />
        </div>

        <button className="hireLawyer mt-3" onClick={() => CheckCard()}>
          Send Message
        </button>
      </div>
      ;
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LawyerModal);
