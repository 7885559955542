import React from "react";
import { Navigate } from "react-router-dom";

type ProtectedRouteProps = {
  token: string | null;
  route?: string;
  children: React.ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ token, route = "/admin", children }) => {
  if (!token) {
    // If no token is present, redirect to the login page
    return <Navigate to={route} replace />;
  }

  // If the token is present, render the children directly
  return <>{children}</>;
};

export default ProtectedRoute;
