import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import LawyerModal from "../../components/LawyerModal";
import { baseUrl, fetchApi } from "../../../../Utiles/connections";
import { ApiRes } from "../../../../Utiles/types";
import moment from "moment";

const mapStateToProps = (state: any) => {
  return {
    modal: state.modal,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setModal: (modal: boolean) =>
      dispatch({ type: "SET_MODAL", payload: modal }),
  };
};

const SinglBlog = ({ modal, setModal }: any) => {
  const navigate = useNavigate();
  const [lawyer, setLawyer] = useState<any>(null);
  const [loading, setLaoding] = useState<boolean>(false);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [LawyerId, setLawyerId] = useState<string>(urlParams.get("id") || "");

  const getLawyer = async () => {
    setLaoding(true);
    const responce: ApiRes = await fetchApi(
      `member/getLawyer?IdLawyer=${LawyerId}`,
      "GET",
      {}
    );
    if (responce.StatusCode === 200) {
      setLawyer(responce.My_Result);
    } else {
      alert("Something went wrong");
    }
    setLaoding(false);
  };

  useEffect(() => {
    getLawyer();
  }, [LawyerId]);

  return (
    <>
      <main id="main">
        <section id="blog" className="blog">
          <div className="container" data-aos="fade-up">
            {!loading && lawyer ? (
              <div className="row">
                <div className="col-lg-4">
                  <div className=" bg-white shadow ">
                    <img
                      src={
                        lawyer.LawyerDetails.ProfilePicture
                          ? `${baseUrl}${lawyer.LawyerDetails.ProfilePicture}`
                          : "/assets/img/blog/blog-1.jpg"
                      }
                      alt=""
                      className="img-fluid"
                    />
                    <div
                      className="p-3 text-center d-flex flex-column"
                      style={{ gap: "10px" }}>
                      <h4>{lawyer.LawyerDetails.FullName}</h4>
                      {/* <a href={`mailto:${lawyer.LawyerDetails.Email}`}>
                        {lawyer.LawyerDetails.Email}
                      </a> */}
                      {/* <a href={`tel:${lawyer.LawyerDetails.Phone}`}>
                        {lawyer.LawyerDetails.Phone}
                      </a> */}
                      <a
                        href={`${baseUrl}${lawyer.LawyerDetails.CV}`}
                        target="_blank">
                        Lawyer CV
                      </a>

                      <p>{lawyer.LawyerDetails.Bio}</p>

                      <div className="d-flex align-items-center justify-content-center">
                        {lawyer.LawyerDetails.SocialMedia?.map(
                          (media: any, index: number) => (
                            <a
                              key={index}
                              className="mx-1 navIcons rounded-5 d-flex align-items-center justify-content-center"
                              style={{ height: "2.5rem", width: "2.5rem" }}
                              href={media.Link}
                              target="_blank">
                              <i className={`bi bi-${media.Icon}`}></i>
                            </a>
                          )
                        )}
                      </div>
                      <button
                        className="navIcons w-100"
                        onClick={() => setModal(true)}>
                        Hire
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 entries">
                  <article className="entry entry-single">
                    <div className="entry-content">
                      {lawyer.LawyerWorkFields?.map(
                        (WorkField: any, index: number) => (
                          <div
                            key={index}
                            className={`${
                              index + 1 !== lawyer.LawyerWorkFields.length &&
                              "border-bottom border-2"
                            }`}>
                            <h3>
                              {moment(WorkField.StartDate).format("DD/MM/YYYY")}{" "}
                              -{" "}
                              {WorkField.EndDate
                                ? moment(WorkField.EndDate).format("DD/MM/YYYY")
                                : "Till Now"}
                            </h3>
                            <h6>
                              {WorkField.WorkField} - {WorkField.JobTitle}
                            </h6>
                            <p>{WorkField.JobDescription}</p>
                          </div>
                        )
                      )}
                    </div>
                  </article>
                </div>
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "75vh" }}>
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
      <LawyerModal />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SinglBlog);
