let user = localStorage.getItem("user");

if (user) {
  user = JSON.parse(user);
}

const initialState = {
  path: sessionStorage.getItem("memberPath") || "/lawyer",
  navigation: [
    {
      id: 0,
      name: "Home",
      path: "/",
      icon: "bi bi-house",
    },
    {
      id: 1,
      name: "Profile",
      path: "/lawyer/profile",
      icon: "bi bi-person",
    },
    {
      id: 3,
      name: "Subscriptions",
      path: "/lawyer/profile/subscriptions",
      icon: "bi bi-credit-card-fill",
    },
    {
      id: 4,
      name: "Appointments",
      path: "/lawyer/profile/appointments",
      icon: "bi bi-calendar2-check-fill",
    },
    {
      id: 2,
      name: "Settings",
      path: "/lawyer/profile/settings",
      icon: "bi bi-gear-fill",
    },
  ],
  open: false,
  token:
    localStorage.getItem("token") || localStorage.getItem("ltoken") || null,
  ltoken: localStorage.getItem("ltoken") || null,
  user: user || null,
};

const rootReducer = (state = initialState, action: any) => {
  console.log(action);

  switch (action.type) {
    case "SET_PATH":
      return { ...state, path: action.payload };
    case "SET_OPEN":
      return { ...state, open: action.payload };
    case "SET_TOKEN":
      localStorage.setItem("token", action.payload);
      return { ...state, token: action.payload };
    case "SET_LTOKEN":
      localStorage.setItem("ltoken", action.payload);
      return { ...state, ltoken: action.payload };

    case "SET_USER":
      localStorage.setItem("user", action.payload.toString());
      return { ...state, user: action.payload };
    default:
      return state;
  }
};
export default rootReducer;
