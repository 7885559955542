import React, { useEffect, useState } from "react";
import { ApiRes } from "../../../../Utiles/types";
import { baseUrl, fetchApi } from "../../../../Utiles/connections";

export default function PortfolioDetails() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [ContractId, setContractId] = useState<string>(
    urlParams.get("id") || ""
  );
  const [contract, setContract] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const GetContract = async () => {
    setLoading(true);
    const responce: ApiRes = await fetchApi(
      `Member/GetContract?IdContract=${ContractId}`,
      "Get",
      {}
    );
    if (responce.StatusCode === 200) {
      setContract(responce.My_Result);
    }
    setLoading(false);
  };

  useEffect(() => {
    GetContract();
  }, [ContractId]);
  return (
    <div>
      {" "}
      <main id="main">
        <section id="portfolio-details" className="portfolio-details">
          <div className="container">
            {!loading && contract ? (
              <div className="row gy-4">
                <div className="col-lg-8">
                  <div className="portfolio-details-slider">
                    <div className="align-items-center">
                      <div className="" style={{ position: "relative" }}>
                        <img
                          src={`${baseUrl}${contract.ImageURL}`}
                          alt=""
                          style={{ position: "relative" }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            background:
                              "linear-gradient(to bottom, transparent, white 50%, white)",
                          }}
                        ></div>
                      </div>

                      {/* <div className="swiper-slide">
                        <img src="/assets/img/portfolio/portfolio-2.jpg" alt="" />
                      </div>

                      <div className="swiper-slide">
                        <img src="/assets/img/portfolio/portfolio-3.jpg" alt="" />
                      </div> */}
                    </div>
                    <div className="swiper-pagination"></div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="portfolio-info">
                    <h3>Contract information</h3>
                    <ul>
                      <li>
                        <strong>Name</strong>:{contract.Title}
                      </li>
                      <li>
                        <strong>Price</strong>: {contract.Price} USD
                      </li>
                      {contract.RequiresLawyer && (
                        <li title="Require a session with on of our Lawyer">
                          <strong className="text-danger">
                            Requires a Lawyer
                          </strong>
                        </li>
                      )}
                    </ul>
                    <button className="navIcons w-100">Buy Now</button>
                  </div>

                  <div className="portfolio-description">
                    <h2>Contract Description</h2>
                    <p>{contract.Description}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "75vh" }}
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
    </div>
  );
}
