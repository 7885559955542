import React from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { servicesColor } from "../../../../Utiles/constants";

const mapStateToProps = (state: any) => {
  return {
    services: state.services,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
  };
};

const Services = ({ services, setPath }: any) => {
  const navigate = useNavigate();

  const NavigateTo = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
    sessionStorage.setItem("path", path);
    setPath(path);
  };
  return (
    <div>
      <main id="main">
        <section className="services">
          <div className="container">
            <div className="row">
              {services.map((service: any, index: number) => (
                <div
                  key={index}
                  className="col-md-6 col-lg-3 d-flex align-items-stretch "
                  data-aos="fade-up"
                  onClick={() => {
                    NavigateTo(service.path);
                  }}>
                  <div
                    className={`icon-box w-100 icon-box-${
                      servicesColor[index % 4]
                    }`}>
                    <div className="icon">
                      <i className={service.icon}></i>
                    </div>
                    <h4 className="title">
                      <a>{service.name}</a>
                    </h4>
                    {/* <p className="description">{service.desc}</p> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="service-details">
          <div className="container">
            <div className="row">
              {services.map((item: any, index: any) => (
                <div
                  key={index}
                  className="col-md-6 d-flex align-items-stretch"
                  data-aos="fade-up">
                  <div className="card">
                    <div className="card-img">
                      <img src={item.image} alt="..." className="w-100" />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">
                        <a href="#">{item.title}</a>
                      </h5>
                      <p className="card-text text-center">{item.desc}</p>
                      <div className="read-more">
                        {/* <a href="#">
                          <i className="bi bi-arrow-right"></i> Read More
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* <section className="pricing section-bg" data-aos="fade-up">
          <div className="container">
            <div className="section-title">
              <h2>Pricing</h2>
              <p>
                Magnam dolores commodi suscipit. Necessitatibus eius consequatur
                ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam
                quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
                Quia fugiat sit in iste officiis commodi quidem hic quas.
              </p>
            </div>

            <div className="row no-gutters">
              <div className="col-lg-4 box">
                <h3>Free</h3>
                <h4>
                  $0<span>per month</span>
                </h4>
                <ul>
                  <li>
                    <i className="bx bx-check"></i> Quam adipiscing vitae proin
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Nec feugiat nisl pretium
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Nulla at volutpat diam
                    uteera
                  </li>
                  <li className="na">
                    <i className="bx bx-x"></i>{" "}
                    <span>Pharetra massa massa ultricies</span>
                  </li>
                  <li className="na">
                    <i className="bx bx-x"></i>{" "}
                    <span>Massa ultricies mi quis hendrerit</span>
                  </li>
                </ul>
                <a href="#" className="get-started-btn">
                  Get Started
                </a>
              </div>

              <div className="col-lg-4 box featured">
                <h3>Business</h3>
                <h4>
                  $29<span>per month</span>
                </h4>
                <ul>
                  <li>
                    <i className="bx bx-check"></i> Quam adipiscing vitae proin
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Nec feugiat nisl pretium
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Nulla at volutpat diam
                    uteera
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Pharetra massa massa
                    ultricies
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Massa ultricies mi quis
                    hendrerit
                  </li>
                </ul>
                <a href="#" className="get-started-btn">
                  Get Started
                </a>
              </div>

              <div className="col-lg-4 box">
                <h3>Developer</h3>
                <h4>
                  $49<span>per month</span>
                </h4>
                <ul>
                  <li>
                    <i className="bx bx-check"></i> Quam adipiscing vitae proin
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Nec feugiat nisl pretium
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Nulla at volutpat diam
                    uteera
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Pharetra massa massa
                    ultricies
                  </li>
                  <li>
                    <i className="bx bx-check"></i> Massa ultricies mi quis
                    hendrerit
                  </li>
                </ul>
                <a href="#" className="get-started-btn">
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </section> */}
      </main>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
