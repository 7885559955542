import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../../Utiles/connections";
import { connect } from "react-redux";
import Loader3 from "../../Shared/Loader3/Loader3";

type InputRefs = (HTMLInputElement | null)[];

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    reset: state.reset,
    LawyerSignup: state.LawyerSignup,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    setToken: (token: string) => dispatch({ type: "SET_TOKEN", payload: token }),

    setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
    setReset: (reset: boolean) => dispatch({ type: "SET_RESET", payload: reset }),
  };
};

const VerificationInput = ({ setToken, setPath, user, reset, setUser, LawyerSignup }: any) => {
  const navigate = useNavigate();

  const navigateTo = (path: string) => {
    navigate(path);
    setPath(path);
  };

  // Assuming a 6-digit code
  const [inputs, setInputs] = useState<string[]>(new Array(6).fill(""));
  const inputRefs = useRef<InputRefs>([]);

  const [showPopUp, setShowPopUp] = useState(false);

  const [Loading, setLoading] = useState(false);

  const handleChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const newInputs = [...inputs]; // Copy current state
    newInputs[index] = event.target.value; // Update the value for the specific input
    setInputs(newInputs); // Set new state

    // Focus the next input field if the value is not empty
    if (event.target.value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace" && !inputs[index] && index > 0) {
      // Focus the previous field if backspace is pressed and the field is already empty
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const result = await fetchApi("Auth/VerifyMemberEmail", "POST", {
      Email: user.Email,
      VerificationCode: +inputs.join(""),
    });
    if (result.StatusCode === 200) {
      setLoading(false);

      setToken(result.My_Result.JWT_Token);

      navigateTo(`${LawyerSignup ? "/lawyer/signup" : reset ? "/reset-password" : "/"}`);
    } else if (result.StatusCode === 409) {
      alert("Wrong verification code inputted");
    }

    setLoading(false);
  };

  const reSend = async () => {
    const result = await fetchApi("Auth/SendMemberVerificationEmail", "POST", {
      Email: user.Email,
    });
    if (result.StatusCode === 200 || result.StatusCode === 400) {
      setUser({ ...user, user: result.My_Result });
    } else {
      alert("Something went wrong, please try again");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center flex-column vh-100 ">
      <div
        className="blur p-4 rounded-4 text-white"
        style={{
          boxShadow: "0 0 20px rgba(255,255,255,1)",
        }}
      >
        <div>
          <h5>Verify your account</h5>
          <div className="text-small">Please verify code sent to your Email</div>
          <div className="fw-bold ">
            <div className="text-primary-dark-blue small">{user.Email.slice(0, 4) + user.Email.slice(4, user.Email.lenght).replace(/./g, "*")}</div>
          </div>
        </div>
        <div className="text-end">
          <a type="button" className="small  mb-2" onClick={reSend}>
            Resend Code
          </a>
        </div>

        <div className="d-flex align-items-center justify-content-between text-dark">
          {inputs.map((value, index) => (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)} // Assign the element to the ref array
              value={value}
              onChange={(event) => handleChange(index, event)}
              onKeyDown={(event) => handleKeyDown(index, event)}
              maxLength={1}
              style={{
                width: "3em",
                textAlign: "center",
                height: "3em",
                color: "var(--primary-dark-blue)",
              }}
              type="text"
              // Additional props for better mobile keyboard support
              inputMode="numeric"
              className={`border rounded form-control p-0 mx-2`}
              pattern="\d*"
              placeholder="-"
              required
            />
          ))}
        </div>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <button onClick={handleSubmit} className="navIcons d-flex align-items-center justify-content-center w-100 text-center" disabled={Loading}>
            {Loading ? <Loader3 /> : "Complete Verification"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificationInput);
