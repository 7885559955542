import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { baseUrl, fetchApi } from "../../../../Utiles/connections";
import { ApiRes } from "../../../../Utiles/types";
import { Collapse } from "react-bootstrap";
import CardLoader from "../../../Shared/CardLoader.tsx/CardLoader";

const mapStateToProps = (state: any) => ({
  path: state.path,
  token: state.token,
  ltoken: state.ltoken,
});

const mapDispatchToProps = (dispatch: any) => ({
  setPath: (path: string) => dispatch({ type: "SET_PATH", payload: path }),
  setLawyerSignUp: (lawyerSignUp: boolean) =>
    dispatch({ type: "SET_LAWYER_SIGNUP", payload: lawyerSignUp }),
});

const Team = ({
  path,
  setPath,
  token,
  ltoken,
  setLawyerSignUp,
}: {
  path: string;
  setPath: any;
  token: string;
  ltoken: string;
  setLawyerSignUp: any;
}) => {
  const navigate = useNavigate();
  const navigateTo = (path: string) => {
    navigate(path);
    setPath(path);
  };

  const [Loading, setLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const [selectedWorkField, setSelectedWorkField] = useState<number>(0);
  const [Offset, setOffset] = useState<number>(0);
  const [selectedYOE, setSelectedYOE] = useState<any>({
    id: 0,
    name: "All",
    StartDate: 0,
    EndDate: null,
  });

  const [open, setOpen] = useState(false);
  const [lawyers, setLawyers] = useState<any[]>([]);
  const [workFields, setWorkFields] = useState<any[]>([]);

  const formatYearsForApi = () => {
    if (selectedYOE.id === 0) {
      return "";
    } else if (selectedYOE.id === 4) {
      return `&MinYOE=${selectedYOE.StartDate}`;
    } else {
      return `&MinYOE=${selectedYOE.StartDate}&MaxYOE=${selectedYOE.EndDate}`;
    }
  };

  const GetLawyers = async () => {
    setLoading(true);
    const responce: ApiRes = await fetchApi(
      `Member/GetLawyers?Offset=${Offset}&IdWorkField=${selectedWorkField}${formatYearsForApi()}}`,
      "GET",
      {}
    );
    if (responce.StatusCode === 200) {
      if (Offset === 0) {
        setLawyers(responce.My_Result);
      } else setLawyers([...lawyers, ...responce.My_Result]);
      if (responce.My_Result.length < 20) {
        setLoading(false);
        setHasMore(false);
        return;
      }
    } else {
      alert(responce.ExceptionMsg);
    }
    setLoading(false);
  };

  const GetWorkFields = async () => {
    const responce: ApiRes = await fetchApi("Lawyer/GetWorkFields", "GET", {});
    if (responce.StatusCode === 200) {
      setWorkFields(responce.My_Result);
    } else {
      alert(responce.ExceptionMsg);
    }
  };

  useEffect(() => {
    GetLawyers();
  }, [Offset]);

  useEffect(() => {
    GetWorkFields();
  }, []);

  const yearsOfExperience = [
    {
      id: 0,
      name: "All",
      StartDate: 0,
      EndDate: null,
    },
    {
      id: 1,
      name: "1-5 years",
      StartDate: 1,
      EndDate: 5,
    },
    {
      id: 2,
      name: "5-10 years",
      StartDate: 5,
      EndDate: 10,
    },
    {
      id: 3,
      name: "10-15 years",
      StartDate: 10,
      EndDate: 15,
    },
    {
      id: 4,
      name: "15+ years",
      StartDate: 15,
      EndDate: null,
    },
  ];

  return (
    <>
      <main id="main">
        {ltoken && (
          <div className="bg-light p-4 d-flex justify-content-between align-items-center">
            <div className="h4 m-0">Click to edit your profile</div>
            <button
              className="navIcons"
              onClick={() => navigateTo("/lawyer/profile")}>
              Edit Your Profile
            </button>
          </div>
        )}
        <section
          className="team pb-0 pt-3"
          data-aos="fade-in"
          data-aos-easing="ease-in-out"
          data-aos-duration="500">
          <div className="container-fluid">
            <div className="my-3">
              <div className="d-flex justify-content-end align-items-center">
                <button
                  className="navIcons px-5"
                  onClick={() => setOpen(!open)}>
                  Filters
                </button>
              </div>
              <Collapse in={open}>
                <div>
                  <div className="py-2 d-flex">
                    <label htmlFor=""></label>
                    <select
                      className="form-select w-50"
                      value={selectedWorkField}
                      onChange={(e) => {
                        setSelectedWorkField(parseInt(e.target.value));
                      }}>
                      <option value={0}>All</option>
                      {workFields.map((field: any, index) => (
                        <option value={field.IdWorkField} key={index}>
                          {field.WorkField}
                        </option>
                      ))}
                    </select>
                    <select
                      className="form-select w-50 mx-2"
                      value={JSON.stringify(selectedYOE)}
                      onChange={(e) => {
                        setSelectedYOE(JSON.parse(e.target.value));
                      }}>
                      {yearsOfExperience.map((yoe, index) => (
                        <option value={JSON.stringify(yoe)} key={index}>
                          {yoe.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button className="navIcons" onClick={() => setOffset(0)}>
                      <i className="bi bi-search" /> Search
                    </button>
                  </div>
                </div>
              </Collapse>
            </div>
            <div className="row">
              {lawyers.map((lawyer: any, index) => (
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch"
                  key={index}>
                  <div
                    className="member"
                    onClick={() => {
                      navigateTo(`/find-a-lawyer/lawyer?id=${lawyer.IdLawyer}`);
                    }}>
                    <div className="member-img">
                      <img
                        src={
                          lawyer.ProfilePicture
                            ? `${baseUrl}${lawyer.ProfilePicture}`
                            : "/assets/images/personPlaceHolder.png"
                        }
                        className="img-fluid"
                        alt=""
                      />
                      <div className="social">
                        <a href="https://www.google.com" target="_blank">
                          <i className="bi bi-twitter"></i>
                        </a>
                        <a href="">
                          <i className="bi bi-facebook"></i>
                        </a>
                        <a href="">
                          <i className="bi bi-instagram"></i>
                        </a>
                        <a href="">
                          <i className="bi bi-linkedin"></i>
                        </a>
                      </div>
                    </div>
                    <div className="member-info">
                      <h4>{lawyer.FullName}</h4>
                      <span>
                        <a href={`mailto:${lawyer.Email}`}>{lawyer.Email}</a>
                      </span>
                      {lawyer.JobTitles.map((JT: string, index: number) => (
                        <span key={index}>
                          {JT} {index + 1 < lawyer.JobTitles.length && "-"}
                        </span>
                      ))}
                      <p className="text-center">{lawyer.Bio}</p>
                    </div>
                  </div>
                </div>
              ))}
              {Loading &&
                Array.from({ length: 6 }).map((_: any, index: number) => {
                  return (
                    <div
                      className="col-lg-4 col-md-6 mb-3 d-flex align-items-stretch"
                      key={index}>
                      <CardLoader />
                    </div>
                  );
                })}
            </div>
            {hasMore && !Loading && (
              <div className="d-flex justify-content-center">
                <button
                  className="navIcons w-100"
                  onClick={() => {
                    setOffset(Offset + 6);
                  }}>
                  Load More
                </button>
              </div>
            )}
          </div>
          {!ltoken && (
            <div className=" p-4 bg-light">
              <div className=" d-flex justify-content-between align-items-center">
                <h3>Are you a Lawyer?</h3>
                <button
                  className="navIcons p-3"
                  onClick={() => {
                    setLawyerSignUp(true);
                    if (token) {
                      navigateTo("/lawyer");
                    } else {
                      navigateTo("/login");
                    }
                  }}>
                  Join our team
                </button>
              </div>
            </div>
          )}
        </section>
      </main>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
